import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalConfiguration } from "./configurations/Security/MsalConfiguration";
import { ParallaxProvider } from "react-scroll-parallax";

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import "./assets/icons/elegant-icons/elegant-icons.css";
import "./assets/css/normalize.css"; 
import "./assets/css/style.css";
import "./assets/css/responsive.css";  

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const instance = new PublicClientApplication(MsalConfiguration);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={instance}>
        <ParallaxProvider>
          <App />
        </ParallaxProvider>
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
);


reportWebVitals();
