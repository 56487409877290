
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Spinner from './component/UI/LoadingSpiner/Spinner'; // Ensure the path is correct
import AppToastContainer from './component/UI/Toaster/AppToastContainer';
import './App.css';


// Lazy-loaded components
const Home = lazy(() => import('./pages/Home'));
const Error = lazy(() => import('./pages/Error'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Immigrations = lazy(() => import('./pages/Immigrations'));
const StudentVisa = lazy(() => import('./pages/StudentVisa'));
const SkilledWorkerVisa = lazy(() => import('./pages/SkilledWorkerVisa'));
const FamilyReunification = lazy(() => import('./pages/FamilyReunification'));
const InvestorPrograms = lazy(() => import('./pages/InvestorPrograms'));
const CitizenshipApplications = lazy(() => import('./pages/CitizenshipApplications'));
const VisaServices = lazy(() => import('./pages/VisaServices'));
const TouristVisas = lazy(()=> import('./pages/TouristVisas'));
const BusinessWorkVisas = lazy(()=> import('./pages/BusinessWorkVisas'));
const VistorVisa = lazy(()=> import('./pages/VisitorVisas'));
const Property = lazy(() => import('./pages/Property'));
const PropertySearchAndAcquisition = lazy(() => import('./pages/PropertySearchAndAcquisition'));
const RealEstateInvestment = lazy(() => import('./pages/RealEstateInvestment'));
const LegalAssistance = lazy(() => import('./pages/LegalAssistance'));
const PropertyManagement = lazy(() => import('./pages/PropertyManagement'));
const OurPartners = lazy(() => import('./pages/OurPartners'));
const Opportunities = lazy(() => import('./pages/Opportunities'));
const ContactUs = lazy(() => import('./pages/Contactus'));
const APITestPage = lazy(() => import('./pages/APITestPage'));
const ApplicationFormContainer = lazy(() => import('./pages/ApplicationFormContainer'));
const GlobalDestinations = lazy(() => import('./pages/GlobalDestinations'));

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
      <div>
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/apitest" element={<APITestPage />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/immigrations" element={<Immigrations />} />
            <Route path="/immigrations/student-visa" element={<StudentVisa />} />
            <Route path="/immigrations/work-visa" element={<SkilledWorkerVisa />} />
            <Route path="/immigrations/family-visa" element={<FamilyReunification />} />
            <Route path="/immigrations/investor-visa" element={<InvestorPrograms />} />
            <Route path="/immigrations/citizenship-applications" element={<CitizenshipApplications />} />
            <Route path="/visa-services" element={<VisaServices />} />
            <Route path="/visa-services/tourist-visas" element={<TouristVisas />} />
            <Route path="/visa-services/business-visa" element={<BusinessWorkVisas />} />
            <Route path="/visa-services/visitor-visas" element={<VistorVisa />} />            
            <Route path="/property-services" element={<Property />} />
            <Route path="/property-services/property-search-acquisition" element={<PropertySearchAndAcquisition />} />
            <Route path="/property-services/real-estate-investment" element={<RealEstateInvestment />} />
            <Route path="/property-services/legal-assistance" element={<LegalAssistance />} />
            <Route path="/property-services/property-management" element={<PropertyManagement />} />
            <Route path="/our-partners" element={<OurPartners />} />
            <Route path="/our-partners/opportunities" element={<Opportunities />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/application-form" element={<ApplicationFormContainer />} />
            <Route path="/global-destinations/:countryName" element={<GlobalDestinations />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </Suspense>
        <AppToastContainer />
      </div>
  );
}

export default App;

